<script setup>

</script>

<template>
  <section>
    <div class="thank-you-container">
      <div class="thank-you-box">
        <h1>Form submitted</h1>
        <p>One of our representatives will be contacting you as soon as possible.</p>
      </div>
      <img src="../assets/img/svg/undraw_order_confirmed_re_g0if.svg" alt="form sent">
    </div>
  </section>
</template>

<style lang="scss" scoped>
  section {
    margin: auto;
    padding: 10px;
    .thank-you-container {
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.4) 0 2px 4px, rgba(0, 0, 0, 0.3) 0 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      background-color: white;
      border-radius: 2px;
      color: #073464;
      text-align: center;
      .thank-you-box {
        h1 {
          line-height: 1;
          margin-bottom: 10px;
        }
        max-width: 450px;
        margin: 0 auto 30px auto;
      }
      img {
        width: 100%;
        max-width: 500px;
      }
    }
  }
</style>